import Steps from "@/service/steps.api";

/*
{
    "id": "a9ba4503-f15a-4e6c-a273-12effc85723e",
    "installationStepTemplateId": "5cc531b0-10b3-4f69-b85e-4e74620d3296",
    "installationId": "2c3e7a9b-1924-43f7-bc72-bead8ddcc744",
    "formId": "f044258f-6745-4d36-a250-2e6f2842eaea",
    "createdAt": "2023-01-31T18:14:48.59394Z",
    "name": "Cuarto: VB",
    "description": "Visto bueno",
    "installationTemplateId": "63162aa0-b088-4e2f-bc1b-91853d789a88",
    "formTemplateId": "a9e8eb37-8cb8-436f-9ace-45cf1d28320f",
    "formTemplateRevision": 1,
    "stepNumber": 4,
    "type": "VB",
    "isDraft": false,
    "formRevisionTimestamp": "2023-03-13T14:45:56.968511Z",
    "icon": "mdi-text-box-check",
    "color": "secondary"
}
modificar json anterior para uno que represente que se terminó la instalacion.
type: "END"
icon: "mdi-check"
color: "success"
añadir atributo disabled: true
*/

const finalStep = {
  installationId: "2c3e7a9b-1924-43f7-bc72-bead8ddcc744",
  createdAt: "2023-01-31T18:14:48.59394Z",
  name: "FINALIZADO",
  description: "Instalacion finalizada",
  stepNumber: 5,
  type: "END",
  isDraft: false,
  icon: "mdi-check",
  color: "success",
};

//steps is an Map object with key as the proyect id and value as a Map object with key as the installation id and value as an array of steps
//each step has an id, name, and description, installationStepTemplateId, installationId, formId, createdAt, updatedAt, isDraft and stepNumber
const state = {
  steps: null,
};

const mutations = {
  addSteps: (state, { projectId, installationId, steps }) => {
    if (!state.steps) {
      state.steps = new Map();
    }
    if (!state.steps.get(projectId)) {
      state.steps.set(projectId, new Map());
    }
    state.steps.get(projectId).set(installationId, steps);
  },
};

const getters = {
  getSteps: (state) => (projectId, installationId) => {
    if (!state.steps) {
      return null;
    }
    return state.steps.get(projectId)?.get(installationId);
  },
  getStepById: (state) => (projectId, installationId, stepId) => {
    if (!state.steps) {
      return null;
    }
    return state.steps
      .get(projectId)
      ?.get(installationId)
      ?.find((step) => step.id === stepId);
  },
  getStepByStepNumber: (state) => (projectId, installationId, stepNumber) => {
    if (!state.steps) {
      console.error("state.steps is null");
      return null;
    }
    const requestedStepNumber = Number(stepNumber);
    const steps = state.steps.get(projectId)?.get(installationId);
    let stepNumbersOfInstallation = [];
    const step = steps.find((step) => {
      stepNumbersOfInstallation.push(step.stepNumber);

      return step.stepNumber === requestedStepNumber;
    });
    if (!step) {
      //si stepNumber es mayor que cualquier de los stepNumbersOfInstallations, entonces retornar el step con el stepNumber más alto
      if (stepNumber > Math.max(...stepNumbersOfInstallation)) {
        return finalStep;
      }
    }
    return step;
  },
  //given a project id and installation id, return the highest step number with isDraft = false
  //if there are no steps with isDraft = false, return 0
  //in this way we can know the next step number to create
  getHighestStepNumber: (state) => (projectId, installationId) => {
    if (!state.steps) {
      return null;
    }

    const steps = state.steps.get(projectId)?.get(installationId);
    if (!steps) {
      return null;
    }
    const stepsWithDraftFalse = steps.filter((step) => {
      return !(step.isDraft === null);
    });
    if (stepsWithDraftFalse.length === 0) {
      return 0;
    }
    return Math.max(...stepsWithDraftFalse.map((step) => step.stepNumber));
  },
  getHighestStep: (state) => (projectId, installationId) => {
    if (!state.steps) {
      return null;
    }
    const steps = state.steps.get(projectId)?.get(installationId);
    if (!steps) {
      return null;
    }
    //si no hay pasos con draft false, retornar el primer paso
    //ocurre cuando se crea una instalacion y no se completado ningun paso
    const stepsWithDraftFalse = steps.filter((step) => {
      return !(step.isDraft === null);
    });
    let highestStep = null;
    if (stepsWithDraftFalse.length === 0) {
      highestStep = steps.find((step) => step.stepNumber === 1);
      highestStep.status = "STARTING";
      return highestStep;
    }
    //Sino, encontrar el paso que tenga el stepNumber más alto
    //encontrar el numero de paso más alto posible en steps
    const highestPosibleStepNumber = Math.max(
      ...steps.map((step) => step.stepNumber)
    );

    //si hay pasos con draft false, retornar el paso con el stepNumber más alto
    let highestCompleteStep = stepsWithDraftFalse.find(
      (step) =>
        step.stepNumber ===
        Math.max(...stepsWithDraftFalse.map((step) => step.stepNumber))
    );
    highestStep = highestCompleteStep;
    //si el paso con el stepNumber más alto es el último paso, retornar el paso final
    if (highestCompleteStep.stepNumber === highestPosibleStepNumber) {
      highestStep.status = "FINISHED";
    } else {
      highestStep.status = "IN PROGRESS";
    }
    return highestStep;
  },
  getStepListByDate: (state) => (projectId) => {
    /*
    return a list of steps of any installation of a project ordered by date
    only if step.isDraft !== null
    */
    if (!state.steps) {
      return null;
    }
    const installationsMap = state.steps.get(projectId);
    if (!installationsMap) {
      return null;
    }
    const stepsList = [];
    for (const [installationId, steps] of installationsMap) {
      stepsList.push(...steps);
    }
    return stepsList
      .filter((step) => step.isDraft !== null)
      .sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
  },
};

const actions = {
  getInstallationSteps: (_store, { projectId, installationId }) => {
    return Steps.getInstallationSteps(projectId, installationId)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  getInstallationStepById: (_store, { projectId, installationId, stepId }) => {
    return Steps.getInstallationStepById(projectId, installationId, stepId)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  postInstallationStep: (
    _store,
    { projectId, installationId, stepId, payload }
  ) => {
    return Steps.postInstallationStep(
      projectId,
      installationId,
      stepId,
      payload
    )
      .then(({ data }) => {
        _store.dispatch("getAllInstallationSteps", {
          projectId,
          installationId,
        });
        return data;
      })
      .catch((err) => console.error(err.response));
  },
  getAllInstallationSteps: (_store) => {
    /*get all installations from vuex store getter 'projects/installations'
    that return a Map object with key as the project id and value as a Map object with key as the installation id and value as an array of installations
    */

    const installations = _store.rootGetters["projects/installations"];
    if (!installations) {
      console.error("installations is null");
      return null;
    }

    return new Promise((resolve, reject) => {
      let promises = [];
      //iterate through the installations Map object
      for (const [projectId, installationsMap] of installations) {
        //iterate through the installations Map object
        for (const [installationId, installation] of installationsMap) {
          //get the steps from the api
          const promise = Steps.getInstallationSteps(projectId, installationId)
            .then(({ data }) => {
              //add the steps to the vuex store
              _store.commit("addSteps", {
                projectId,
                installationId,
                steps: data.installationSteps,
              });
            })
            .catch((err) => console.error(err.response));
          promises.push(promise);
        }
      }
      Promise.all(promises).then(() => {
        resolve();
      });
    });
  },
  resetStepsStore: (_store) => {
    _store.state.steps = null;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
