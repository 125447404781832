import Devices from "@/service/device.api";

/*state devices es un Map object. La key es el id del device y el value es el device
    id,name, description, company, projectId (nullable), deviceModelId,createdAt, updatedAt and properties
*/

const genericDeviceData = {
  state: {
    deviceModels: null,
  },
  getters: {
    deviceModels: (state) => {
      //return deviceModels as an array of objects deviceModel
      if (!state.deviceModels) return null;
      return Array.from(state.deviceModels.values());
    },
  },
  mutations: {
    setDeviceModels: (state, deviceModels) => {
      //deviceModel es un array de objetos deviceModel
      if (!state.deviceModels) state.deviceModels = new Map();
      deviceModels.forEach((deviceModel) => {
        state.deviceModels.set(deviceModel.id, deviceModel);
      });
    },
  },
  actions: {
    getDeviceModels: (_store) =>
      Devices.getDeviceModels()
        .then(({ data }) => {
          _store.commit("setDeviceModels", data.deviceModels);
          return data.deviceModels;
        })
        .catch((err) => console.error(err.response)),
  },
};

const generalDevices = {
  state: {
    devices: null,
  },
  getters: {
    devices: (state) => {
      //return devices as an array of objects device
      if (!state.devices) return null;
      return Array.from(state.devices.values());
    },
    deviceById: (state) => (id) => {
      if (!state.devices) return null;
      return state.devices.get(id);
    },
    devicesWithoutProject: (state) => {
      if (!state.devices) return null;
      return Array.from(state.devices.values()).filter(
        (device) => device.projectId === null
      );
    },
    devicesByProjectId: (state) => (projectId) => {
      if (!state.devices) return null;
      return Array.from(state.devices.values()).filter(
        (device) => device.projectId === projectId
      );
    },
  },
  mutations: {
    setDevices: (state, devices) => {
      //device es un array de objetos device
      if (!state.devices) state.devices = new Map();
      devices.forEach((device) => {
        state.devices.set(device.id, device);
      });
    },
    setDevice: (state, device) => {
      //device es un objeto device
      if (!state.devices) state.devices = new Map();
      state.devices.set(device.id, device);
    },
  },
  actions: {
    getDevices: (_store) =>
      Devices.getDevices()
        .then(({ data }) => {
          _store.commit("setDevices", data.devices);
          return data.devices;
        })
        .catch((err) => console.error(err.response)),
    getDeviceById: (_store, id) =>
      Devices.getDeviceById(id)
        .then(({ data }) => {
          return data.device;
        })
        .catch((err) => console.error(err.response)),
    attachDeviceToProject: (_store, { deviceId, projectId }) =>
      Devices.attachDeviceToProject(deviceId, projectId)
        .then(({ data }) => {
          _store.commit("setDevice", data.device);
          return data.device;
        })
        .catch((err) => console.error(err.response)),
    createDevice: (_store, device) =>
      Devices.postDevice(device)
        .then(({ data }) => {
          _store.commit("setDevice", data.device);
          return data.device;
        })
        .catch((err) => console.error(err.response)),
    updateDevice: (_store, { deviceId, device }) =>
      Devices.putDevice(deviceId, device)
        .then(({ data }) => {
          _store.commit("setDevice", data.device);
          return data.device;
        })
        .catch((err) => console.error(err.response)),
  },
};

//devicetasktemplates y devicesteptemplates son Map objects. La key es el id del template y el value es el template

const templatesDevices = {
  state: {
    deviceTaskTemplates: null,
    deviceStepTemplates: null,
  },
  getters: {
    deviceTaskTemplates: (state) => {
      //return deviceTaskTemplates as an array of objects deviceTaskTemplate
      if (!state.deviceTaskTemplates) return null;
      return Array.from(state.deviceTaskTemplates.values());
    },
    deviceTaskTemplateById: (state) => (id) => {
      if (!state.deviceTaskTemplates) return null;
      return state.deviceTaskTemplates.get(id);
    },
    deviceStepTemplates: (state) => {
      //return deviceStepTemplates as an array of objects deviceStepTemplate
      if (!state.deviceStepTemplates) return null;
      return Array.from(state.deviceStepTemplates.values());
    },
    deviceStepTemplateById: (state) => (id) => {
      if (!state.deviceStepTemplates) return null;
      return state.deviceStepTemplates.get(id);
    },
  },
  mutations: {
    setDeviceTaskTemplates: (state, deviceTaskTemplates) => {
      //deviceTaskTemplates es un array de objetos deviceTaskTemplate
      if (!state.deviceTaskTemplates) state.deviceTaskTemplates = new Map();
      deviceTaskTemplates.forEach((deviceTaskTemplate) => {
        state.deviceTaskTemplates.set(
          deviceTaskTemplate.id,
          deviceTaskTemplate
        );
      });
    },
    addDeviceTaskTemplate: (state, deviceTaskTemplate) => {
      //deviceTaskTemplate es un objeto deviceTaskTemplate
      if (!state.deviceTaskTemplates) state.deviceTaskTemplates = new Map();
      state.deviceTaskTemplates.set(deviceTaskTemplate.id, deviceTaskTemplate);
    },

    setDeviceStepTemplates: (state, deviceStepTemplates) => {
      //deviceStepTemplates es un array de objetos deviceStepTemplate
      if (!state.deviceStepTemplates) state.deviceStepTemplates = new Map();
      deviceStepTemplates.forEach((deviceStepTemplate) => {
        state.deviceStepTemplates.set(
          deviceStepTemplate.id,
          deviceStepTemplate
        );
      });
    },
    addDeviceStepTemplate: (state, deviceStepTemplate) => {
      //deviceStepTemplate es un objeto deviceStepTemplate
      if (!state.deviceStepTemplates) state.deviceStepTemplates = new Map();
      state.deviceStepTemplates.set(deviceStepTemplate.id, deviceStepTemplate);
    },
  },

  actions: {
    //device task templates
    getDeviceTaskTemplates: (_store) =>
      Devices.getDeviceTaskTemplates()
        .then(({ data }) => {
          _store.commit("setDeviceTaskTemplates", data.deviceTaskTemplates);
          return data.deviceTaskTemplates;
        })
        .catch((err) => console.error(err.response)),
    getDeviceTaskTemplateById: (_store, id) =>
      Devices.getDeviceTaskTemplateById(id)
        .then(({ data }) => {
          return data.deviceTaskTemplate;
        })
        .catch((err) => console.error(err.response)),
    //device step templates
    getDeviceStepTemplates: (_store, taskTemplateId) =>
      Devices.getDeviceTaskStepTemplates(taskTemplateId)
        .then(({ data }) => {
          _store.commit("setDeviceStepTemplates", data.deviceTaskStepTemplates);
          return data.deviceStepTemplates;
        })
        .catch((err) => console.error(err.response)),
    getDeviceStepTemplateById: (_store, id) =>
      Devices.getDeviceTaskStepTemplateById(id)
        .then(({ data }) => {
          return data.deviceStepTemplate;
        })
        .catch((err) => console.error(err.response)),
    getAllDeviceStepTemplates: (_store) => {
      const devicesTaskTemplates = _store.getters.deviceTaskTemplates;
      return new Promise((resolve, reject) => {
        let deviceStepTemplates = [];
        devicesTaskTemplates.forEach((deviceTaskTemplate) => {
          const taskTemplateId = deviceTaskTemplate.id;
          deviceStepTemplates.push(
            _store.dispatch("getDeviceStepTemplates", taskTemplateId)
          );
        });
        Promise.all(deviceStepTemplates)
          .then((data) => {
            resolve(data);
          })
          .catch((err) => reject(err));
      });
    },
  },
};

const deviceTasks = {
  state: {
    deviceTasks: null,
  },
  getters: {
    deviceTasks: (state) => {
      //return deviceTasks as an array of objects deviceTask
      if (!state.deviceTasks) return null;
      const deviceTasks = Array.from(state.deviceTasks.values());
      return deviceTasks;
    },
    deviceTaskById: (state) => (id) => {
      if (!state.deviceTasks) return null;
      return state.deviceTasks.get(id);
    },
    deviceTasksByDeviceId: (state) => (deviceId) => {
      //find deviceTasks by deviceId and return them as an array of objects deviceTask
      if (!state.deviceTasks) return null;
      const deviceTasks = Array.from(state.deviceTasks.values());
      const deviceTasksByDeviceId = deviceTasks.filter((deviceTask) => {
        return deviceTask.deviceId === deviceId;
      });
      return deviceTasksByDeviceId;
    },
  },
  mutations: {
    setDeviceTasks: (state, deviceTasks) => {
      //deviceTasks es un array de objetos deviceTask
      if (!state.deviceTasks) state.deviceTasks = new Map();
      deviceTasks.forEach((deviceTask) => {
        state.deviceTasks.set(deviceTask.id, deviceTask);
      });
    },
    setDeviceTask: (state, deviceTask) => {
      //deviceTask es un objeto deviceTask
      if (!state.deviceTasks) state.deviceTasks = new Map();
      state.deviceTasks.set(deviceTask.id, deviceTask);
    },
  },
  actions: {
    getDeviceTasks: (_store, deviceId) =>
      Devices.getDeviceTasks(deviceId)
        .then(({ data }) => {
          _store.commit("setDeviceTasks", data.deviceTasks);
          return data.deviceTasks;
        })
        .catch((err) => console.error(err.response)),
    getDeviceTaskById: (_store, { deviceId, taskId }) =>
      Devices.getDeviceTaskById(deviceId, taskId)
        .then(({ data }) => {
          return data.deviceTask;
        })
        .catch((err) => console.error(err.response)),
    createDeviceTask: (_store, { deviceId, deviceTask }) => {
      return Devices.postDeviceTask(deviceId, deviceTask)
        .then(({ data }) => {
          _store.commit("setDeviceTask", data.deviceTask);
          return data.deviceTask;
        })
        .catch((err) => console.error(err.response));
    },
    getAllDeviceTasks: (_store) => {
      const devices = _store.getters.devices;
      return new Promise((resolve, reject) => {
        let promise = [];
        devices.forEach((device) => {
          const deviceId = device.id;
          promise.push(_store.dispatch("getDeviceTasks", deviceId));
        });
        Promise.all(promise).then(() => {
          resolve();
        });
      });
    },
  },
};

const deviceSteps = {
  state: {
    deviceSteps: null,
  },
  getters: {
    deviceSteps: (state) => {
      //return deviceSteps as an array of objects deviceStep
      if (!state.deviceSteps) return null;
      return Array.from(state.deviceSteps.values());
    },
    deviceStepsByTaskId: (state) => (taskId) => {
      //find deviceSteps by taskId and return them as an array of objects deviceStep
      if (!state.deviceSteps) return null;
      return Array.from(state.deviceSteps.values()).filter(
        (deviceStep) => deviceStep.deviceTaskId === taskId
      );
    },
    getDeviceStepByStepNumber: (state) => (taskId, stepNumber) => {
      //find deviceSteps by taskId and return them as an array of objects deviceStep
      if (!state.deviceSteps) return null;
      const step = Array.from(state.deviceSteps.values()).filter(
        (deviceStep) => {
          return (
            deviceStep.deviceTaskId === taskId &&
            deviceStep.stepNumber === stepNumber
          );
        }
      );
      if (!step) return null;
      return step[0];
    },
    getCurrentDeviceStep: (state) => (taskId) => {
      //current step is the step with isDraft===null with the lowest stepNumber
      //if there is no step with isDraft===null, current step is the step with isDraft===false with the lowest stepNumber
      if (!state.deviceSteps) return null;
      const deviceSteps = Array.from(state.deviceSteps.values()).filter(
        (deviceStep) => deviceStep.deviceTaskId === taskId
      );
      //encontrar el step mas avanzado y entregar el siguiente
      //el step mas avanzando es aquel entre los que tiene isDraft=false, con el stepNumber mas alto
      //si no hay ninguno con isDraft=false, se devuelve el que tenga stepNumber=1
      const taskStepsNumber = deviceSteps.length;

      const devicesWithDraftFalse = deviceSteps.filter(
        (deviceStep) => deviceStep.isDraft === false
      );

      let currentDeviceStep = null;
      //si no existen deviceSteps con isDraft=false, se devuelve el que tenga stepNumber=1
      if (devicesWithDraftFalse.length === 0) {
        currentDeviceStep = deviceSteps.find(
          (deviceStep) => deviceStep.stepNumber === 1
        );
        currentDeviceStep.taskStatus = "STARTING";
        return currentDeviceStep;
      }
      //existe al menos un step con isDraft=false, que es el mas avanzado
      const highestDeviceStep = devicesWithDraftFalse.reduce((prev, current) =>
        prev.stepNumber > current.stepNumber ? prev : current
      );
      //done REVISAR, se deberia retorna otra cosa
      if (highestDeviceStep.stepNumber === taskStepsNumber) {
        currentDeviceStep = highestDeviceStep;
        currentDeviceStep.taskStatus = "FINISHED";
        return currentDeviceStep;
      }
      //si el step mas avanzado no es el ultimo, se devuelve el siguiente
      currentDeviceStep = deviceSteps.find(
        (deviceStep) =>
          deviceStep.stepNumber === highestDeviceStep.stepNumber + 1
      );
      currentDeviceStep.taskStatus = "RUNNING";
      return currentDeviceStep;
    },
    getHighestDeviceStep: (state) => (taskId) => {
      if (!state.deviceSteps) return null;
      const deviceSteps = Array.from(state.deviceSteps.values()).filter(
        (deviceStep) => deviceStep.deviceTaskId === taskId
      );

      const taskStepsNumber = deviceSteps.length;

      const devicesWithDraftFalse = deviceSteps.filter(
        (deviceStep) => deviceStep.isDraft === false
      );

      let highestDeviceStep = null;
      //si no existen deviceSteps con isDraft=false, se devuelve el que tenga stepNumber=1
      if (devicesWithDraftFalse.length === 0) {
        highestDeviceStep = deviceSteps.find(
          (deviceStep) => deviceStep.stepNumber === 1
        );
        highestDeviceStep.taskStatus = "STARTING";
        return highestDeviceStep;
      }
      //existe al menos un step con isDraft=false, que no necesariamente es el último
      //por tanto devolver el que tenga el stepNumber más alto
      //si el stepNumber más alto es igual al número de steps de la tarea, se devuelve FINISHED
      //si no, se devuelve IN_PROGRESS

      let highestStepNumber = 0;
      devicesWithDraftFalse.forEach((deviceStep) => {
        if (deviceStep.stepNumber > highestStepNumber) {
          highestStepNumber = deviceStep.stepNumber;
          highestDeviceStep = deviceStep;
          if (highestStepNumber === taskStepsNumber) {
            highestDeviceStep.taskStatus = "FINISHED";
          } else {
            highestDeviceStep.taskStatus = "IN_PROGRESS";
          }
        }
      });
      return highestDeviceStep;
    },
  },
  mutations: {
    setDeviceSteps: (state, deviceSteps) => {
      //deviceSteps es un array de objetos deviceStep
      if (!state.deviceSteps) state.deviceSteps = new Map();
      deviceSteps.forEach((deviceStep) => {
        state.deviceSteps.set(deviceStep.id, deviceStep);
      });
    },
    setDeviceStep: (state, deviceStep) => {
      //deviceStep es un objeto deviceStep
      if (!state.deviceSteps) state.deviceSteps = new Map();
      state.deviceSteps.set(deviceStep.id, deviceStep);
    },
  },
  actions: {
    getDeviceSteps: (_store, { deviceId, taskId }) => {
      return Devices.getDeviceTaskSteps(deviceId, taskId)
        .then(({ data }) => {
          _store.commit("setDeviceSteps", data.deviceTaskSteps);
          return data.deviceSteps;
        })
        .catch((err) => console.error(err.response));
    },
    getDeviceStepById: (_store, { deviceId, taskId, stepId }) => {
      return Devices.getDeviceTaskStepById(deviceId, taskId, stepId)
        .then(({ data }) => {
          return data.deviceStep;
        })
        .catch((err) => console.error(err.response));
    },
    createDeviceStep: (_store, { deviceId, taskId, stepId, payload }) => {
      return Devices.postDeviceTaskStep(deviceId, taskId, stepId, payload)
        .then(({ data }) => {
          return data.form;
        })
        .catch((err) => console.error(err.response));
    },
    getAllDeviceSteps: (_store) => {
      if (!_store.state.deviceTasks) return;
      const tasks = Array.from(_store.state.deviceTasks.values());
      // const tasks = _store.getters.deviceTasks;
      /* deviceTask trae deviceId y su taskId */
      return new Promise((resolve, reject) => {
        let promise = [];
        tasks.forEach((deviceTask) => {
          const deviceId = deviceTask.deviceId;
          const taskId = deviceTask.id;
          promise.push(_store.dispatch("getDeviceSteps", { deviceId, taskId }));
        });
        Promise.all(promise).then(() => {
          resolve();
        });
      });
    },
  },
};

//unificacin de los stores
const STORE_ATTRIBUTES = ["state", "getters", "mutations", "actions"];
const STORES = [
  genericDeviceData,
  generalDevices,
  templatesDevices,
  deviceTasks,
  deviceSteps,
];

const UNIFIED_STORE = STORE_ATTRIBUTES.reduce((acc, attr) => {
  acc[attr] = {};
  STORES.forEach((store) => {
    acc[attr] = { ...acc[attr], ...store[attr] };
  });
  return acc;
}, {});

export default {
  namespaced: true,
  ...UNIFIED_STORE,
};
