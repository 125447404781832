import axios from "axios";
import store from "../store";
import router from "../router";

const BASE_URL = process.env.VUE_APP_BASE_URL;
const MAX_ONGOING_REQUESTS = 10;

/**
 * Si ocurre 404 para esta ruta, entonces se hace logout
 */
const LOGOUT_PATH_URL_CASE = "/renew";

const httpClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const setHttpClientAuth = (token) => {
  //!datos del jwt al store
  httpClient.defaults.headers["Authorization"] = `Bearer ${token}`;
};

function deleteCookiesByExpirationDate() {
  console.log("cookies detected", document.cookie);
  const cookies = document.cookie.split("; ");
  for (let c = 0; c < cookies.length; c++) {
    const d = window.location.hostname.split(".");
    while (d.length > 0) {
      const cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      const p = window.location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }
  console.log("cookies deleted", document.cookie);
}

/**
 * Sistema de encolado de peticiones usando axios interceptors
 * Se usa cuando existe mas de MAX_ONGOING_REQUESTS peticiones al mismo tiempo
 * El numero de peticiones activas de almacena en una variable de este archivo
 * Cuando la peticion se resuelve, se resta 1 a la variable
 * y se resuelve la siguiente peticion en la cola en queuedRequests, resolviendo FIFO
 */
let onGoingRequests = 0;
let queuedRequests = [];
httpClient.interceptors.request.use(
  (req) => {
    //get all cookies, parse them and rewrite with expiration date one day in the past
    const cookies = document.cookie;

    if (cookies) deleteCookiesByExpirationDate();

    if (onGoingRequests >= MAX_ONGOING_REQUESTS) {
      return new Promise((resolve, reject) => {
        queuedRequests.push({ resolve, reject, req });
      });
    }
    onGoingRequests++;
    return req;
  },
  (err) => err
);

httpClient.interceptors.response.use(
  (res) => {
    if (onGoingRequests > 0) {
      onGoingRequests--;
    }
    if (queuedRequests.length > 0) {
      const { resolve, reject, req } = queuedRequests.shift();
      onGoingRequests++;
      resolve(req);
    }
    return res;
  },
  (error) => {
    let response = error.response;
    const isTokenExpired =
      response.data.msg?.includes("expiration") ?? "no-msg";
    switch (response.status) {
      case 401:
        if (isTokenExpired) {
          return store.dispatch("auth/renewToken").then((newToken) => {
            setHttpClientAuth(newToken);
            response.config.headers.Authorization = `Bearer ${newToken}`;
            return axios.request(response.config);
          });
          router.push({ name: "login" });
        } else {
          store.commit("auth/setIsAuthenticated", false);
          router.push({ name: "login" });
          //refresh window
          window.location.reload();
        }
        break;
      case 404:
        const isException =
          response.request.responseURL.includes(LOGOUT_PATH_URL_CASE);
        if (isException) {
          store.dispatch("auth/signOut");
          router.push({ name: "login" });
        } else {
          // router.push({ name: "404" });
        }
        break;
      default:
        // console.error("error interceptor", error.response);
        return Promise.reject(response);
    }
  }
);

export default httpClient;
