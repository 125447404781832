import httpClient from "./httpClient";

const baseEndpoint = process.env.VUE_APP_BASE_ENDPOINT;

//contacts api
const getContacts = (clientId) =>
  httpClient.get(`${baseEndpoint}/clients/${clientId}/contacts`);

const getProjectContacts = (projectId) =>
  httpClient.get(`${baseEndpoint}/projects/${projectId}/contacts`);

const getContactById = (clientId, contactId) =>
  httpClient.get(`${baseEndpoint}/clients/${clientId}/contacts/${contactId}`);

const postContact = (clientId, contact) =>
  httpClient.post(`${baseEndpoint}/clients/${clientId}/contacts`, contact);

const putContact = (clientId, contactId, contact) =>
  httpClient.put(
    `${baseEndpoint}/clients/${clientId}/contacts/${contactId}`,
    contact
  );

const linkContactsToProject = (projectId, contactsId) =>
  httpClient.put(`${baseEndpoint}/projects/${projectId}/contacts/link`, {
    contacts: contactsId,
  });

const unlinkContactsFromProject = (projectId, contactsId) =>
  httpClient.put(`${baseEndpoint}/projects/${projectId}/contacts/unlink`, {
    contacts: contactsId,
  });

export default {
  getContacts,
  getProjectContacts,
  getContactById,
  postContact,
  putContact,
  linkContactsToProject,
  unlinkContactsFromProject,
};
