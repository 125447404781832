import Contacts from "@/service/contacts.api";

//contacts es un map object, la key es la id del proyecto y el value es un array de objetos contact

const state = {
  projectContacts: null,
};
const getters = {
  projectContacts: (state) => {
    if (!state.projectContacts) return null;
    return Array.from(state.projectContacts.values());
  },
  projectContactsByProjectId: (state) => (projectId) => {
    if (!state.projectContacts) return null;
    return state.projectContacts.get(projectId);
  },
};
const mutations = {
  setProjectContacts: (state, { projectId, projectContacts }) => {
    if (!state.projectContacts) state.projectContacts = new Map();
    state.projectContacts.set(projectId, projectContacts);
  },
  addProjectContact: (state, { projectId, contact }) => {
    if (!state.projectContacts) state.projectContacts = new Map();
    const projectContacts = state.projectContacts.get(projectId);
    if (!projectContacts) state.projectContacts.set(projectId, [contact]);
    else state.projectContacts.set(projectId, [...projectContacts, contact]);
  },
};
const actions = {
  getProjectContacts: (_store, projectId) => {
    return Contacts.getProjectContacts(projectId)
      .then(({ data }) => {
        const projectContacts = data.contacts ?? [];
        _store.commit("setProjectContacts", { projectId, projectContacts });
        return data.contacts;
      })
      .catch((err) => console.error(err.response));
  },
  createContact: (_store, { clientId, contact, projectId }) => {
    return Contacts.postContact(clientId, contact)
      .then(({ data }) => {
        //add to store if projectId is provided
        const newContact = data.contact;
        if (projectId && newContact)
          _store
            .dispatch("linkContactsToProject", {
              projectId,
              contactsId: [newContact.id],
            })
            .then((res) => {
              _store.dispatch("getProjectContacts", projectId);
            });
        return data;
      })
      .catch((err) => console.error(err.response));
  },
  updateContact: (_store, { clientId, contactId, contact, projectId }) => {
    return Contacts.putContact(clientId, contactId, contact)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => console.error(err.response));
  },
  linkContactsToProject: (_store, { projectId, contactsId }) => {
    return Contacts.linkContactsToProject(projectId, contactsId)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => console.error(err.response));
  },
  unlinkContactsFromProject: (_store, { projectId, contactsId }) => {
    return Contacts.unlinkContactsFromProject(projectId, contactsId)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => console.error(err.response));
  },
  getAllContactsByProjects: (_store) => {
    //get projects from store projects getter
    const projects = _store.rootGetters["projects/projects"];
    if (!projects) return;

    return new Promise((resolve, reject) => {
      let promises = [];
      //iterate through the projects array
      projects.forEach((project) => {
        //get the contacts from the api
        const promise = _store.dispatch("getProjectContacts", project.id);
        promises.push(promise);
      });
      Promise.all(promises).then(() => {
        resolve();
      });
    });
  },
  resetContactsStore: ({ state }) => {
    state.projectContacts = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
