const state = {
  isOffline: false,
  newUpdateAvailable: false,
};

const mutations = {
  setOffline(state, isOffline) {
    state.isOffline = isOffline;
  },
  setNewUpdateAvailable(state, newUpdateAvailable) {
    state.newUpdateAvailable = newUpdateAvailable;
  },
};

const getters = {
  isOffline(state) {
    return state.isOffline;
  },
  newUpdateAvailable(state) {
    return state.newUpdateAvailable;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
