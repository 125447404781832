import axios from "axios";
import httpClient from "@/service/httpClient";

const BASE_URL = process.env.VUE_APP_BASE_URL;

const baseEndpoint = process.env.VUE_APP_BASE_ENDPOINT;

const signIn = (email, password) =>
  axios.post(
    `${BASE_URL}${baseEndpoint}/user/sign/in`,
    {
      email,
      password,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 3000,
    }
  );

const renewToken = (refreshToken) =>
  httpClient.post(`${baseEndpoint}/token/renew`, {
    refreshToken,
  });

const signOut = () => httpClient.post(`${baseEndpoint}/user/sign/out`);

const getUser = () => httpClient.get(`${baseEndpoint}/current-user`);
const getUsers = () => httpClient.get(`${baseEndpoint}/users`);

export default {
  signIn,
  renewToken,
  signOut,
  getUser,
  getUsers,
};
