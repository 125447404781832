export const IMAGE_DEFAULT_LOADING = "/img_cantload.png";
export const IMAGE_DEFAULT_ERROR = "/img_error.png";

export const HEADER_FIELDS_DEFAULT = {
  form_name: "Formulario",
  filled_by: "Llenado por",
  date: "Fecha de Llenado",
  plate: "Vehiculo",
};

