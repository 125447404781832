import Templates from "@/service/template.api";

/*form templates, installation templates, and installation step templates
are all Map objects.  The key is the id of the template and the value is the template itself.
Ideally, they would all be filled at the start of the application and then never changed.
*/

//form templates
const formTemplates = {
  state: {
    formTemplates: null,
  },
  getters: {
    getFormTemplateById: (state) => (id) => {
      if (!state.formTemplates) {
        console.error("getFormTemplateById: state.form" + "Templates is null");
        return null;
      }
      return state.formTemplates.get(id);
    },
    getFormTemplates: (state) => {
      //return an array of formTemplates
      if (!state.formTemplates) {
        console.error("getFormTemplates: state.formTemplates is null");
        return null;
      }
      return Array.from(state.formTemplates.values());
    },
  },
  mutations: {
    setFormTemplates(state, formTemplates) {
      //formTemplates is an array of formTemplates
      if (!formTemplates) {
        console.error("setFormTemplates: formTemplates is null");
        return;
      }
      state.formTemplates = new Map();
      if (formTemplates.length === 0) {
        console.error("Empty formTemplates");
        return;
      }
      formTemplates.forEach((formTemplate) => {
        state.formTemplates.set(formTemplate.id, formTemplate);
      });
    },
  },
  actions: {
    getFullFormTemplates: (_store) => {
      return Templates.getFullFormTemplates().then((response) => {
        //TODO: change attribute name to formTemplates
        _store.commit("setFormTemplates", response.data.formTemplates);
        return response;
      });
    },
  },
};

//installation templates
const installationTemplates = {
  state: {
    installationTemplates: null,
  },
  getters: {
    getInstallationTemplateById: (state) => (id) => {
      if (!state.installationTemplates) {
        console.error(
          "getInstallationTemplateById: state.installation" +
            "Templates is null"
        );
        return null;
      }
      return state.installationTemplates.get(id);
    },
    getInstallationTemplates: (state) => {
      //return an array of installationTemplates
      if (!state.installationTemplates) {
        console.error(
          "getInstallationTemplates: state.installationTemplates is null"
        );
        return null;
      }
      return Array.from(state.installationTemplates.values());
    },
  },
  mutations: {
    setInstallationTemplates(state, installationTemplates) {
      //installationTemplates is an array of installationTemplates
      if (!installationTemplates) {
        console.error(
          "setInstallationTemplates: installationTemplates is null"
        );
        return;
      }
      state.installationTemplates = new Map();
      if (installationTemplates.length === 0) {
        console.error("Empty installationTemplates");
        return;
      }
      installationTemplates.forEach((installationTemplate) => {
        state.installationTemplates.set(
          installationTemplate.id,
          installationTemplate
        );
      });
    },
  },
  actions: {
    getInstallationTemplates: (_store) => {
      return Templates.getInstallationTemplates()
        .then((response) => {
          _store.commit(
            "setInstallationTemplates",
            response.data.installationTemplates
          );
          return response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};

//installation step templates
const installationStepsTemplates = {
  state: {
    installationStepsTemplates: null,
  },
  getters: {
    InstallationStepTemplateById: (state) => (id) => {
      if (!state.installationStepsTemplates) {
        console.error(
          "getInstallationStepTemplateById: state.installation" +
            "StepTemplates is null"
        );
        return null;
      }
      return state.installationStepsTemplates.get(id);
    },
  },
  mutations: {
    addInstallationStepTemplate(state, installationStepTemplate) {
      if (!installationStepTemplate) {
        console.error(
          "addInstallationStepTemplate: installationStepTemplate is null"
        );
        return;
      }
      if (!state.installationStepsTemplates) {
        //init the map
        state.installationStepsTemplates = new Map();
        console.error("Empty installationStepsTemplates, init map");
      }
      state.installationStepsTemplates.set(
        installationStepTemplate.id,
        installationStepTemplate
      );
    },
  },
  actions: {
    getinstallationStepsTemplatesByInstallationId: (_store, installationId) => {
      return Templates.getInstallationStepsTemplatesByInstallationId(
        installationId
      )
        .then((response) => {
          const installationStepsTemplates =
            response.data.installationStepTemplates;
          if (!installationStepsTemplates) {
            console.error(
              "getinstallationStepsTemplatesByInstallationId: " +
                "installationStepsTemplates is null"
            );
            return;
          }
          installationStepsTemplates.forEach((installationStepTemplate) => {
            _store.commit(
              "addInstallationStepTemplate",
              installationStepTemplate
            );
          });
          return response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};

const state = {
  ...formTemplates.state,
  ...installationTemplates.state,
  ...installationStepsTemplates.state,
};

const getters = {
  ...formTemplates.getters,
  ...installationTemplates.getters,
  ...installationStepsTemplates.getters,
  InstallationStepFormTemplateById: (state) => (InstallationStepId) => {
    if (!state.installationStepsTemplates) {
      console.error(
        "getInstallationStepFormTemplateById: state.installation" +
          "StepTemplates is null"
      );
      return null;
    }
    const formTemplateId =
      state.installationStepsTemplates.get(InstallationStepId)?.formTemplateId;
    if (!formTemplateId) {
      console.error(
        "getInstallationStepFormTemplateById: formTemplateId is null"
      );
      return null;
    }
    return state.formTemplates.get(formTemplateId);
  },
  InstallationStepsTemplatesByInstallationId: (state) => (installationId) => {
    if (!state.installationStepsTemplates) {
      console.error(
        "getinstallationStepsTemplatesByInstallationId: state.installation" +
          "StepTemplates is null"
      );
      return null;
    }
    let installationStepsTemplates = [];
    state.installationStepsTemplates.forEach((installationStepTemplate) => {
      if (installationStepTemplate.installationTemplateId === installationId)
        installationStepsTemplates.push(installationStepTemplate);
    });
    return installationStepsTemplates;
  },
};

const mutations = {
  ...formTemplates.mutations,
  ...installationTemplates.mutations,
  ...installationStepsTemplates.mutations,
};

const actions = {
  ...formTemplates.actions,
  ...installationTemplates.actions,
  ...installationStepsTemplates.actions,
  getAllInstallationStepsTemplates: (_store) => {
    if (!_store.state.installationTemplates) {
      console.error(
        "getAllInstallationStepsTemplates: installationTemplates is null"
      );
      return;
    }

    return new Promise((resolve, reject) => {
      let promiseStepsTemplates = [];
      //get all installationIds given the installationTemplates is a Map object with key = id and value = installationTemplate
      const installationIds = Array.from(
        _store.state.installationTemplates.values()
      ).map((installationTemplate) => installationTemplate.id);
      //get all installationStepsTemplates given the installationIds
      installationIds.forEach((installationId) => {
        const promise = _store.dispatch(
          "getinstallationStepsTemplatesByInstallationId",
          installationId
        );
        promiseStepsTemplates.push(promise);
      });
      Promise.all(promiseStepsTemplates).then((responses) => {
        resolve(responses);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
