import httpClient from "./httpClient";
const baseEndpoint = process.env.VUE_APP_BASE_ENDPOINT;

const getClients = () => httpClient.get(`${baseEndpoint}/clients`);
const getClientById = (id) => httpClient.get(`${baseEndpoint}/clients/${id}`);
const getClientsWithUnits = () =>
  httpClient.get(`${baseEndpoint}/clients-with-units`);
const createClient = (client) =>
  httpClient.post(`${baseEndpoint}/clients`, client);
const updateClient = (clientId, client) => {
  return httpClient.put(`${baseEndpoint}/clients/${clientId}`, client);
};

export default {
  getClients,
  getClientById,
  getClientsWithUnits,
  createClient,
  updateClient,
};
