import Units from "@/service/units.api";

const state = {
  units: null,
};

const mutations = {
  setUnits(state, units) {
    state.units = units ?? [];
  },
  addUnits(state, units) {
    if (!state.units) {
      state.units = [];
    }
    //add new units without duplicates
    units.forEach((unit) => {
      if (!state.units.find((u) => u.id === unit.id)) {
        state.units.push(unit);
      }
    });
  },
};

const getters = {
  units: (state) => state.units,
  unitById: (state) => (id) => {
    return state.units.find((unit) => unit.id === id);
  },
  unitsByClientId: (state) => (clientId) => {
    return state.units.filter((unit) => {
      return unit.clientId === clientId;
    });
  },
};

const actions = {
  getUnits: (_store, clientId) => {
    return Units.getUnits(clientId)
      .then(({ data }) => {
        _store.commit("addUnits", data.units);
        return data.units;
      })
      .catch((err) => console.error(err.response));
  },
  getUnitById: (_store, { clientId, unitId }) => {
    return Units.getUnitById(clientId, unitId)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  createUnit: (_store, { clientId, unit }) => {
    return Units.createUnit(clientId, unit)
      .then(({ data }) => {
        _store.dispatch("getAllUnits");
        return data;
      })
      .catch((err) => console.error(err.response));
  },
  updateUnit: (_store, { clientId, unitId, unit }) => {
    return Units.updateUnit(clientId, unitId, unit)
      .then(({ data }) => {
        _store.dispatch("getAllUnits");
        return data;
      })
      .catch((err) => console.error(err.response));
  },
  // action getUnitsByProjectId using the same api as getUnits and
  //getting the clientId from the project getter projectById
  getUnitsByProjectId: (_store, projectId) => {
    const clientId = _store.getters["projects/clientId"](projectId);
    return Units.getUnits(clientId)
      .then(({ data }) => {
        _store.commit("setUnits", data.units);
        return data.units;
      })
      .catch((err) => console.error(err.response));
  },
  getAllUnits: (_store) => {
    //obtener listado de proyectos
    const projects = _store.rootGetters["projects/projects"];
    //de cada proyecto obtener clientId
    const clientIds = projects.map((project) => project.clientId);
    //remove duplicates
    const uniqueClientIds = [...new Set(clientIds)];
    return new Promise((resolve, reject) => {
      const promises = uniqueClientIds.map((clientId) => {
        return Units.getUnits(clientId)
          .then(({ data }) => {
            _store.commit("addUnits", data.units);
            return data.units;
          })
          .catch((err) => console.error(err.response));
      });
      Promise.all(promises).then(() => {
        resolve();
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
