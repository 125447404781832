const EssentialActions = [
  "projects/getProjectsWithNestedInstallations",
  "templates/getFullFormTemplates",
  "templates/getInstallationTemplates",
  "templates/getAllInstallationStepsTemplates",
  "units/getAllUnits",
  "devices/getDevices",
  "devices/getDeviceModels",
];
/**
 * Routes that are essential for the app to work
 * and use StaleWhileRevalidate strategy
 */
const ESSENTIAL_ROUTES_AND_MUTATIONS = new Map([
  ["full-form-templates", "templates/setFormTemplates"],
  ["projects-with-nested-data", "projects/setProjectsFromCache"],
  ["installation-templates", "templates/setInstallationTemplates"],
  ["clients-with-units", 'clients/setNestedClientFromCache']
]);

// essential routes are the keys of the map
const ESSENTIAL_ROUTES = [...ESSENTIAL_ROUTES_AND_MUTATIONS.keys()];

/*
 */
const cacheFirstRegexs = [
  //regex for routes that have this pattern /api/v1/installation-templates/9c7a3beb-cd97-498d-9b2b-33db3109cf64/steps
  //considering that the uuid as any length and any
  /\/api\/v1\/installation-templates\/[a-zA-Z0-9-]*\/steps/,
];

export default EssentialActions;

export { EssentialActions, ESSENTIAL_ROUTES, ESSENTIAL_ROUTES_AND_MUTATIONS, cacheFirstRegexs };
