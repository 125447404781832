<template>
  <v-sheet class="p-2 position-absolute top-50 start-50 translate-middle">
    <div class="row align-items-center justify-content-center">
      <v-progress-circular
        :size="200"
        color="secondary"
        indeterminate
        :width="8"
      >
        <v-img
          class="col-12"
          style="filter: grayscale(0%)"
          src="/img/icons/favicon-32x32.png"
          max-width="70%"
        >
        </v-img>
      </v-progress-circular>
      <div class="row text-center d-inline-flex">
        <span class="fw-bold">Cargando{{ points }}</span>
      </div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "LoaderIcon",
  created() {
    // every 0.5 seconds add a string point to this.points to a max of 6 and return to 0
    this.points = "";
    setInterval(() => {
      this.points += ".";
      if (this.points.length > 6) {
        this.points = "";
      }
    }, 500);
  },
  data() {
    return {
      points: "",
    };
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>