import ImageApi from "@/service/image.api";
import { IMAGE_DEFAULT_ERROR } from "@/utils/defaultValues";

const state = {
  images: new Map(),
};
const mutations = {
  addImage(state, payload) {
    state.images.set(payload.id, payload.url);
  },
  removeImage(state, payload) {
    state.images.delete(payload.id);
  },
  setImages(state, payload) {
    state.images = payload;
  },
  resetImages(state) {
    //revoke all urls in state.images Map
    state.images.forEach((url) => {
      URL.revokeObjectURL(url);
    });
    state.images = new Map();
  },
};
const getters = {
  imageById: (state) => (id) => state.images.get(id),
};
const actions = {
  uploadImage: (_store, image) =>
    ImageApi.uploadImage(image)
      .then((res) => {
        // console.log("Store:Upload Image ok:", res);
        return res;
      })
      .catch((err) => {
        console.error("Store:Upload Image err:", err);
        Promise.resolve({});
      }),
  getImageURL: (_store, id) =>
    ImageApi.getImageURL(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error("Store:Get Image err:", err);
        return Promise.reject({});
      }),

  getImageById: (_store, id) =>
    ImageApi.getImageById(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        // console.error("Store:Get Image by Id error:", err);
        return Promise.reject(err);
        // throw new Error(IMAGE_DEFAULT_ERROR);
      }),
  resetImageStore: ({ commit }) => {
    commit("resetImages");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
