import httpClient from "./httpClient";

const baseEndpoint = process.env.VUE_APP_BASE_ENDPOINT;

//device api

const getDevices = () => httpClient.get(`${baseEndpoint}/devices`);
const getDeviceById = (id) => httpClient.get(`${baseEndpoint}/devices/${id}`);
const postDevice = (device) =>
  httpClient.post(`${baseEndpoint}/devices`, device);
const putDevice = (deviceId, device) =>
  httpClient.put(`${baseEndpoint}/devices/${deviceId}`, device);
//device-models api

const getDeviceModels = () => httpClient.get(`${baseEndpoint}/device-models`);
const getDeviceModelById = (id) =>
  httpClient.get(`${baseEndpoint}/device-models/${id}`);

//device-brands api

const getDeviceBrands = () => httpClient.get(`${baseEndpoint}/device-brands`);
const getDeviceBrandById = (id) =>
  httpClient.get(`${baseEndpoint}/device-brands/${id}`);

//device-types api

const getDeviceTypes = () => httpClient.get(`${baseEndpoint}/device-types`);
const getDeviceTypeById = (id) =>
  httpClient.get(`${baseEndpoint}/device-types/${id}`);

//done device-task-templates api
const getDeviceTaskTemplates = () =>
  httpClient.get(`${baseEndpoint}/device-task-templates`);
const getDeviceTaskTemplateById = (id) =>
  httpClient.get(`${baseEndpoint}/device-task-templates/${id}`);

//done device-task-step-templates api
const getDeviceTaskStepTemplates = (taskTemplateId) =>
  httpClient.get(
    `${baseEndpoint}/device-task-templates/${taskTemplateId}/steps`
  );
const getDeviceTaskStepTemplateById = (taskTemplateId, stepId) =>
  httpClient.get(
    `${baseEndpoint}/device-task-templates/${taskTemplateId}/steps/${stepId}`
  );

//done device-tasks api
const getDeviceTasks = (id) =>
  httpClient.get(`${baseEndpoint}/devices/${id}/tasks`);
const getDeviceTaskById = (deviceId, taskId) =>
  httpClient.get(`${baseEndpoint}/devices/${deviceId}/tasks/${taskId}`);
const postDeviceTask = (deviceId, task) =>
  httpClient.post(`${baseEndpoint}/devices/${deviceId}/tasks`, task);

//done device-task-steps api
const getDeviceTaskSteps = (deviceId, taskId) =>
  httpClient.get(`${baseEndpoint}/devices/${deviceId}/tasks/${taskId}/steps`);
const getDeviceTaskStepById = (deviceId, taskId, stepId) =>
  httpClient.get(
    `${baseEndpoint}/devices/${deviceId}/tasks/${taskId}/steps/${stepId}`
  );
const postDeviceTaskStep = (deviceId, taskId, stepId, form) =>
  httpClient.post(
    `${baseEndpoint}/devices/${deviceId}/tasks/${taskId}/steps/${stepId}/forms`,
    form
  );

export default {
  getDevices,
  getDeviceById,
  postDevice,
  putDevice,
  getDeviceModels,
  getDeviceModelById,
  getDeviceBrands,
  getDeviceBrandById,
  getDeviceTypes,
  getDeviceTypeById,
  getDeviceTaskTemplates,
  getDeviceTaskTemplateById,
  getDeviceTaskStepTemplates,
  getDeviceTaskStepTemplateById,
  getDeviceTasks,
  getDeviceTaskById,
  postDeviceTask,
  getDeviceTaskSteps,
  getDeviceTaskStepById,
  postDeviceTaskStep,
};
