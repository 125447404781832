import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

import freshLoad from "@/router/freshLoad";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/MainMenuView.vue"),
    meta: {
      requiresAuth: true,
      showNav: false,
      title: "InstallCheck",
      section: "InstallCheck",
      requiredPermissions: [],
      needOnline: false,
    },
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/TheTestView.vue"),
    meta: {
      requiresAuth: true,
      showNav: false,
      title: "InstallCheck",
      section: "TEST | InstallCheck",
      requiredPermissions: [],
      needOnline: false,
    },
  },
  {
    path: "/config",
    name: "config",
    component: () => import("@/views/ConfigView.vue"),
    meta: {
      requiresAuth: true,
      showNav: false,
      title: "InstallCheck",
      section: "Config | InstallCheck",
      requiredPermissions: [],
      needOnline: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
    meta: {
      requiresAuth: false,
      showNav: false,
      title: "Login | InstallCheck",
      section: "InstallCheck",
      requiredPermissions: [],
      needOnline: false,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("@/views/SignUpView.vue"),
    meta: {
      requiresAuth: true,
      showNav: false,
      title: "Signup | InstallCheck",
      section: "InstallCheck",
      requiredPermissions: ["tempform:create"],
      needOnline: true,
    },
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import("@/views/MainMenuView.vue"),
    meta: {
      requiresAuth: true,
      showNav: false,
      title: "InstallCheck | InstallCheck",
      section: "Menu",
      requiredPermissions: [],
      needOnline: false,
    },
  },
  {
    path: "/allunits",
    name: "allunits",
    component: () => import("@/views/GeneralUnitsView.vue"),
    meta: {
      requiresAuth: true,
      showNav: false,
      title: "Unidades | InstallCheck",
      section: "Unidades | InstallCheck",
      requiredPermissions: [],
      needOnline: true,
    }
  },
  {
    path: "/forms",
    name: "forms",
    component: () => import("@/views/TheFormView.vue"),
    meta: {
      requiresAuth: true,
      showNav: true,
      title: "Forms | InstallCheck",
      section: "Formularios | InstallCheck",
      requiredPermissions: [],
      needOnline: false,
    },
    children: [
      {
        name: "form-selection",
        path: "selection",
        component: () => import("@/components/FormCardSelection.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "Forms | InstallCheck",
          section: "Formularios | InstallCheck",
          requiredPermissions: ["form:create"],
          needOnline: false,
        },
      },
      {
        name: "table",
        path: "table",
        component: () => import("@/views/FormDashboard.vue"),
        meta: {
          requiresAuth: true,
          showNav: true,
          title: "Forms | InstallCheck",
          section: "Formularios | InstallCheck",
          requiredPermissions: ["form:read"],
          needOnline: true,
        },
        children: [
          {
            name: "table-view",
            path: "view",
            component: () =>
              import("@/components/formsviewers/FormsDataTable.vue"),
            meta: {
              requiresAuth: true,
              showNav: false,
              title: "Forms | InstallCheck",
              section: "Formularios | InstallCheck",
              requiredPermissions: ["form:read"],
              needOnline: true,
            },
          },
          {
            name: "form-view",
            path: "form/:id",
            component: () =>
              import("@/components/formsviewers/TheFormViewer.vue"),
            meta: {
              requiresAuth: true,
              showNav: true,
              title: "Forms | InstallCheck",
              section: "Formulario",
              requiredPermissions: ["form:read"],
              needOnline: true,
            },
          },
        ],
      },
      {
        name: "checklist",
        path: "checklist/:id",
        component: () => import("@/views/step/TheNewStepManager.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "Check | InstallCheck",
          section: "Checklist",
          requiredPermissions: ["form:create"],
          needOnline: false,
        },
        children: [],
      },
      {
        path: "status",
        name: "form-status",
        component: () => import("@/views/FormStatusView.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "InstallCheck",
          section: "Finalizado",
          requiredPermissions: ["form:create"],
          needOnline: false,
        },
      },
      {
        name: "resume",
        path: "resume",
        component: () => import("@/components/TheResumeChecklist.vue"),
        meta: {
          requiresAuth: true,
          showNav: true,
          title: "Resume | InstallCheck",
          section: "Resumen",
          requiredPermissions: ["form:create"],
          needOnline: false,
        },
      },
    ],
  },
  {
    path: "/drafts",
    name: "drafts",
    component: () => import("@/views/drafts/TheDraftView.vue"),
    meta: {
      requiresAuth: true,
      showNav: false,
      title: "Borradores | InstallCheck",
      section: "Borradores",
      requiredPermissions: [],
      needOnline: false,
    },
    children: [
      {
        path: "selection",
        name: "draft-selection",
        component: () => import("@/components/FormCardSelection.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "Borradores | InstallCheck",
          section: "Borradores",
          requiredPermissions: [],
          needOnline: false,
        },
      },
      {
        name: "draft-checklist",
        path: "checklist/:id",
        component: () => import("@/views/step/TheNewStepManager.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "Check | InstallCheck",
          section: "Checklist Borrador",
          requiredPermissions: ["form:create"],
          needOnline: false,
        },
        children: [],
      },
      {
        path: "status",
        name: "draft-status",
        component: () => import("@/views/FormStatusView.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "InstallCheck",
          section: "Finalizado",
          requiredPermissions: ["form:create"],
          needOnline: false,
        },
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/errors/TheNotFoundView.vue"),
    meta: {
      requiresAuth: false,
      showNav: false,
      title: "InstallCheck",
      section: "404",
      requiredPermissions: [],
      needOnline: false,
    },
  },
  {
    path: "/offline",
    name: "offline",
    component: () => import("@/views/errors/TheOnlineNeeded.vue"),
    meta: {
      requiresAuth: false,
      showNav: false,
      title: "InstallCheck",
      section: "Offline",
      requiredPermissions: [],
      needOnline: false,
    },
  },
  {
    path: "/projects",
    name: "projects",
    component: () => import("@/views/ProjectsView.vue"),
    meta: {
      requiresAuth: true,
      showNav: false,
      title: "InstallCheck",
      section: "Proyectos",
      requiredPermissions: [],
      needOnline: false,
    },
    children: [
      {
        path: "selector",
        name: "projects-list",
        component: () => import("@/components/Project/ProjectsSelector.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "InstallCheck",
          section: "Proyectos",
          requiredPermissions: [],
          needOnline: false,
        },
      },
      {
        path: ":projectId/menu",
        name: "project-menu",
        component: () => import("@/components/Project/ProjectMenu.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "InstallCheck",
          section: "Menu",
          requiredPermissions: [],
          needOnline: false,
        },
      },

      {
        path: ":projectId/installations",
        name: "project-installations",
        component: () => import("@/views/InstallationSelection.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "InstallCheck",
          section: "Instalaciones",
          requiredPermissions: [],
          needOnline: false,
        },
        children: [],
      },
      {
        //confguracion de dispositivos (tasks)
        path: ":projectId/tasks",
        name: "project-tasks",
        component: () => import("@/views/TaskSelection.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "InstallCheck",
          section: "Tareas",
          requiredPermissions: [],
          needOnline: false,
        },
      },
      {
        name: "installation-step",
        path: ":projectId/installations/:installationId/step/:stepNumber",
        component: () => import("@/views/InstallationStep.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "InstallCheck",
          section: "Instalaciones",
          requiredPermissions: [],
          needOnline: false,
        },
      },
      {
        name: "task-step",
        path: ":projectId/tasks/:taskId/step/:stepNumber",
        component: () => import("@/views/InstallationStep.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "InstallCheck",
          section: "Tareas",
          requiredPermissions: [],
          needOnline: false,
        },
      },
      {
        name: "installation-field-signature",
        path: ":projectId/installations/:installationId/step/signature/:stepNumber",
        component: () => import("@/components/SignatureCard.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "InstallCheck",
          section: "Firmas",
          requiredPermissions: [],
          needOnline: false,
        },
      },
      {
        name: "installation-incident",
        path: ":projectId/installations/:installationId/incident",
        component: () => import("@/views/InstallationIncident.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "InstallCheck",
          section: "Instalaciones",
          requiredPermissions: [],
          needOnline: false,
        },
      },
      {
        path: ":projectId/manager",
        name: "project-manager",
        component: () => import("@/views/Project/ProjectAdministrator.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "InstallCheck",
          section: "Manager",
          requiredPermissions: [],
          needOnline: false,
        },
        children: [
          {
            path: "dashboard",
            name: "project-dashboard",
            component: () => import("@/views/Project/ProjectDashboard.vue"),
            meta: {
              requiresAuth: true,
              showNav: false,
              title: "InstallCheck",
              section: "Dashboard",
              requiredPermissions: [],
              needOnline: false,
            },
          },
          {
            path: "installations",
            name: "project-installations-table",
            component: () => import("@/views/InstallationsTable.vue"),
            meta: {
              requiresAuth: true,
              showNav: false,
              title: "InstallCheck",
              section: "Instalaciones",
              requiredPermissions: [],
              needOnline: false,
            },
          },
          {
            path: "clients",
            name: "project-client",
            component: () => import("@/views/Project/ProjectClient.vue"),
            meta: {
              requiresAuth: true,
              showNav: false,
              title: "InstallCheck",
              section: "Clientes",
              requiredPermissions: [],
              needOnline: false,
            },
          },
          {
            // configurations
            path: "configurations",
            name: "project-configurations",
            component: () => import("@/views/TasksTable.vue"),
            meta: {
              requiresAuth: true,
              showNav: false,
              title: "InstallCheck",
              section: "Configuraciones",
              requiredPermissions: [],
              needOnline: false,
            },
          },
        ],
      },
      {
        path: "/:projectId/manager/installations/:installationId/form/:formId",
        name: "form-viewer",
        component: () =>
          import("@/components/duplicates/formsviewers/TheFormViewer.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "InstallCheck",
          section: "Instalaciones",
          requiredPermissions: [],
          needOnline: false,
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "test") return next();
  document.title = to.meta.title ?? "InstallCheck";
  // Prevenir redudant route
  if (from.name === to.name) return false;
  // ruta no permitida?
  if (to.name == "home") return next({ name: "projects-list" });
  //Caso sin tokens en local
  const isLocalEmpty =
    localStorage.getItem("token") === null ||
    localStorage.getItem("refresh_token") === null;
  const requiresAuth = to.meta.requiresAuth;

  if (isLocalEmpty && requiresAuth) {
    setTimeout(() => {
      store.dispatch("resetAllStates");
    }, 500);
    return next({ name: "login" });
  }

  //si la ruta tiene projectId y/o installationId
  const excludedRoutes = ["project-manager"];
  const matchedRoutesNames = to.matched.map((route) => route.name);
  const isAuthenticated = store.getters["auth/getIsAuthenticated"];
  const isFreshReload = store.getters["getIsFreshReload"];
  if (
    !excludedRoutes.some((route) => matchedRoutesNames.includes(route)) &&
    isAuthenticated &&
    !isFreshReload
  ) {
    const projectId = to.params.projectId;
    const installationId = to.params.installationId;
    let projectName = "";
    let installationName = "";

    if (projectId) {
      projectName = store.getters["projects/projectById"](projectId).name ?? "";
      to.meta.section = `${projectName}`;
    }
    if (installationId) {
      installationName =
        store.getters["projects/installationById"](projectId, installationId)
          .name ?? "";
      to.meta.section = `${installationName}`;
    }
  }

  // va a ruta pero no está autenticado

  if (requiresAuth && !isAuthenticated) {
    if (isFreshReload) {
      freshLoad(to, from, next);
    } else {
      store.dispatch("auth/signOut");
      return next({ name: "login" });
    }
  }
  const userPermissions = store.getters["auth/userPermissions"] ?? [];
  const routePermissions = to.meta.requiredPermissions ?? [];

  //check if all routePermissions are in userPermissions
  const hasPermissions = routePermissions.every((permission) =>
    userPermissions.includes(permission)
  );
  if (!hasPermissions) {
    return next({ name: "404" });
  }
  next();
});

router.beforeResolve((to, from, next) => {
  store.getters["pwa/isOffline"] && to.meta.needOnline
    ? next({ name: "offline" })
    : next();
});

export default router;
