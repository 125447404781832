import httpClient from "@/service/httpClient";

const baseEndpoint = `${process.env.VUE_APP_BASE_ENDPOINT}/user`;

const signUp = (userData) =>
  httpClient.post(`${baseEndpoint}/sign/up`, userData);

const getUser = () => httpClient.get(`${baseEndpoint}/current-user`);
const getUsers = () => httpClient.get(`${baseEndpoint}s`);
const getUserById = (id) => httpClient.get(`${baseEndpoint}/${id}`);

export default {
  signUp,
  getUser,
  getUsers,
  getUserById,
};
