import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import tgcDynamicForms from "@tgl/tgc-dynamic-forms";

Vue.use(tgcDynamicForms);
Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
