<template>
  <v-app-bar v-if="showNavbar" bottom fixed color="" elevation="1" app>
    <v-btn @click="goBack" color="second">
      <v-icon small>mdi-arrow-left</v-icon>
      <span class="btn-text"> Atras </span>
    </v-btn>
    <v-spacer> </v-spacer>
    <span class="text-center">
      {{ navbarState.middleText }}
    </span>
    <v-spacer />
    <v-btn color="third" @click="goNext" :disabled="navbarState.disabled">
      <span class="btn-text">
        {{ navbarState.text }}
      </span>

      <v-icon small>mdi-arrow-right</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TheFormAppbar",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {
    goBack() {
      if (this.$route.name == "resume") {
        this.$store.commit("forms/setCanShowResume", false);
        this.$store.commit("forms/setCanStepAdvance", true);
        this.$router.go(-1);
      } else if (this.currentStep > 0) {
        this.$store.commit("forms/setCurrentStep", this.currentStep - 1);
      } else {
        this.$router.go(-1);
      }
    },
    goNext() {
      if (this.navbarState.onNext.use) {
        this.navbarState.onNext.handler();
        return true;
      } else if (this.$route.name == "resume") {
        this.$router.push({ name: "status" });
      } else if (this.canShowResume && !this.canAdvance) {
        this.$router.push({ name: "resume" });
      } else if (this.canAdvance) {
        this.$store.dispatch("forms/advanceCurrentStep");
      }
      window.scrollTo(0, 0);
    },
  },
  computed: {
    ...mapGetters("forms", [
      "canAdvance",
      "canShowResume",
      "canSendForm",
      "currentStep",
    ]),
    showNavbar() {
      return this.$route.meta.showNav;
    },
    navbarState() {
      return this.$store.getters["navbar/getNextBtn"];
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-text {
  font-size: 0.8rem;
}
</style>