import Forms from "@/service/newForms.api";
import inputMapper from "@/utils/inputMapper";

function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

const state = {};

const mutations = {};

const getters = {};

const actions = {
  getFormById: (_store, id) =>
    Forms.getFormById(id)
      .then(({ data }) => {
        let form = data.form;
        const formTemplateId = form.bassedOn;
        const formTemplate = deepClone(
          _store.rootGetters["templates/getFormTemplateById"](formTemplateId)
        );
        //llenado del template con los datos del form
        const formTemplateMap = inputMapper(formTemplate.formAttrs[0]);
        const formKeys = Object.keys(form.formAttrs[0]);
        formKeys.forEach((key) => {
          if (!formTemplateMap.has(key)) {
            console.error("no existe la key en el template", key);
            return;
          }
          formTemplateMap.get(key).value = form.formAttrs[0][key];
        });

        return { form, formTemplateMap };
      })
      .catch((err) => console.error(err.response)),
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
