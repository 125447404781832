import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
  },
  theme: {
    themes: {
      light: {
        first: "#0261DB",
        second: "#00B7D6",
        third: "#157145",
        fourth: "#f9a620",
        fifth: "#F6EFA6",
        //
        primary: "#0261DB",
        secondary: "#00B7D6",
        accent: "#72BDA3",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
});
