import Projects from "@/service/projects.api";
import Vue from "vue";

//projects

const state = {
  projects: [],
  currentProject: null,
  installations: null,
  currentInstallation: null,
  incidents: null,
};

const mutations = {
  //projects
  setProjects(state, projects) {
    state.projects = projects ?? [];
  },
  setProjectsFromCache(state, projects) {
    if (!projects) return;
    //guardar los datos de proyecto en el store
    projects.forEach((project) => {
      this.commit("projects/setProjectNested", project);
    });
  },
  setProjectNested(state, project) {
    if (!project) return;
    //guardar los datos de proyecto en el store
    const cleanProject = {
      id: project.id,
      clientId: project.clientId,
      name: project.name,
      description: project.description,
      createdAt: project.createdAt,
      updatedAt: project.updatedAt,
    };
    //check if state.projects is empty
    if (!state.projects) {
      //state.projects must be an array
      //use Vue.set to make it reactive and put cleanProject as the first element
      Vue.set(state, "projects", [cleanProject]);
      return;
    }
    //check if project.id is in the projects array
    const index = state.projects.findIndex(
      (projectInStore) => projectInStore.id === cleanProject.id
    );
    if (index === -1) {
      //use Vue.set to make it reactive and push cleanProject
      Vue.set(state.projects, state.projects.length, cleanProject);
    } else {
      //use Vue.set to make it reactive and update the project
      Vue.set(state.projects, index, cleanProject);
    }
    //guardar datos de instalaciones en el store de instalaciones
    this.commit("projects/setInstallationsByProject", {
      projectId: project.id,
      installations: project.installations,
    });
    //guardar datos de contactos en el store de contactos
    this.commit("contacts/setProjectContacts", {
      projectId: project.id,
      projectContacts: project.contacts,
    });
  },
  setCurrentProject(state, projectId) {
    //find the project by id and set it as currentProject
    state.currentProject = state.projects.find(
      (project) => project.id === projectId
    );
    //set currentInstallation
    //return the currentProject
    return state.currentProject;
  },
  //installations
  setInstallation(state, { installation, steps, projectId }) {
    if (!installation || !projectId) {
      console.error("setInstallation: installation or projectId is null");
      return;
    }
    //installation is an object with the installation data
    //this functions will set the installation object as a Map object with the key as the installation id inside
    //a Map object with key as the project id
    if (!state.installations) {
      state.installations = new Map();
    }
    if (!state.installations.get(projectId))
      state.installations.set(projectId, new Map());

    if (!installation.id) {
      console.error("Empty installation::ProjectId:", projectId);
      return;
    }
    state.installations.get(projectId).set(installation.id, {
      //id, createdAt, updatedAt, name, description, installationTemplateId, unitId, incidentTemplateId,projectId,estimatedStartDate,estimatedCompletitionDate
      id: installation.id,
      createdAt: installation.createdAt,
      updatedAt: installation.updatedAt,
      name: installation.name,
      description: installation.description,
      installationTemplateId: installation.installationTemplateId,
      unitId: installation.unitId,
      incidentTemplateId: installation.incidentTemplateId,
      projectId: installation.projectId,
      estimatedStartDate: installation.estimatedStartDate,
      estimatedCompletitionDate: installation.estimatedCompletitionDate,
      steps: steps,
    });
    if (steps) {
      this.commit("steps/addSteps", {
        installationId: installation.id,
        steps: steps,
        projectId: projectId,
      });
    }
  },
  setInstallationsByProject(state, { installations, projectId }) {
    if (!installations || !projectId) {
      console.error(
        "setInstallationsByProject: installations or projectId is null"
      );
      return;
    }
    /*
    installations is an array of installations for a project defined by projectId
    this functions will set the installations array as a Map object with the key as the installation id inside
    a Map object with key as the project id
    */
    if (!state.installations) {
      state.installations = new Map();
    }
    if (!state.installations.get(projectId))
      state.installations.set(projectId, new Map());

    if (installations.length === 0 || installations === null) {
      console.error("Empty installations::ProjectId:", projectId);
      state.installations.set(projectId, []);
      return;
    }
    installations.forEach((installation) => {
      state.installations.get(projectId).set(installation.id, {
        //id, createdAt, updatedAt, name, description, installationTemplateId, unitId, incidentTemplateId,projectId,estimatedStartDate,estimatedCompletitionDate
        id: installation.id,
        createdAt: installation.createdAt,
        updatedAt: installation.updatedAt,
        name: installation.name,
        description: installation.description,
        installationTemplateId: installation.installationTemplateId,
        unitId: installation.unitId,
        incidentTemplateId: installation.incidentTemplateId,
        projectId: installation.projectId,
        estimatedStartDate: installation.estimatedStartDate,
        estimatedCompletitionDate: installation.estimatedCompletitionDate,
      });
      //si vienen pasos de instalacion, entonces guardar donde corresponde
      if (installation.installationSteps) {
        this.commit("steps/addSteps", {
          installationId: installation.id,
          steps: installation.installationSteps,
          projectId: projectId,
        });
      }
    });
  },
  setCurrentInstallation(state, { projectId, installationId }) {
    if (!state.installations) {
      console.error("setCurrentInstallation: state.installations is null");
      return;
    }
    state.currentInstallation = state.installations
      .get(projectId)
      ?.get(installationId);
  },
  //incidents
  setIncidentsByInstallation(state, { incidents, projectId, installationId }) {
    if (!incidents || !projectId || !installationId) {
      console.error(
        "setIncidentsByInstallation: incidents or projectId or installationId is null"
      );
      return;
    }
    if (!state.incidents) {
      state.incidents = new Map();
    }
    if (!state.incidents.get(projectId)) {
      state.incidents.set(projectId, new Map());
    }
    state.incidents.get(projectId).set(installationId, incidents);
  },
};
const getters = {
  //dar formato a los proyectos para ser util en ProjectList.vue
  projectsList: (state) => {
    return state.projects.map((project) => ({
      title: project.name,
      line1: project.description,
      line2: `Ultima actualizacion: ${new Date(
        project.updatedAt
      ).toLocaleString(navigator.language, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })}`,
      id: project.id,
      icon: project.icon ?? "mdi-apache-kafka",
      color: project.color ?? "primary",
      to: {
        name: "project-menu",
        params: {
          projectId: project.id,
        },
      },
    }));
  },
  projects: (state) => {
    //return array of projects
    return state.projects;
  },
  projectById: (state) => (id) => {
    return state.projects.find((project) => project.id === id);
  },
  clientId: (state) => (id) => {
    return state.projects.find((project) => project.id === id).clientId;
  },
  currentProject: (state) => state.currentProject,
  currentClientId: (state) => state.currentProject.clientId,
  //instalaciones
  installationsByProject: (state) => (projectId) => {
    if (!state.installations) {
      return null;
    }
    return state.installations.get(projectId);
  },
  currentInstallation: (state) => state.currentInstallation,
  currentProjectInstallations: (state) => {
    const projectId = state.currentProject?.id;
    if (!projectId) return null;
    return Array.from(state.installations.get(projectId)?.values());
  },
  installationById: (state) => (projectId, installationId) => {
    if (!state.installations) {
      return null;
    }
    //get keys from installations Map
    const keys = Array.from(state.installations.keys());
    if (!state.installations.has(projectId)) return null;
    return state.installations.get(projectId).get(installationId);
  },
  installations: (state) => state.installations,
  getInstallationByUnitIdAndProjectId: (state) => (projectId, unitId) => {
    if (!state.installations) {
      return null;
    }
    const installations = state.installations.get(projectId);
    if (!installations) {
      return null;
    }
    return Array.from(installations.values()).find((installation) => {
      return installation.unitId === unitId;
    });
  },
  //incidents
  incidentsByInstallation: (state) => (projectId, installationId) => {
    if (!state.incidents) {
      return null;
    }
    return state.incidents.get(projectId).get(installationId);
  },
};
const actions = {
  //proyectos
  getProjects: (_store) => {
    return Projects.getProjects()
      .then(({ data }) => {
        _store.commit("setProjects", data.projects);
        return data;
      })
      .catch((err) => console.error(err.response));
  },
  getProjectById: (_store, id) => {
    return Projects.getProjectById(id)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  getProjectsWithNestedInstallations: (_store) => {
    return Projects.getProjectsWithNestedInstallations()
      .then(({ data }) => {
        const projects = data.projects;
        //for each project, commit to setProjectNested
        projects.forEach((project) => {
          _store.commit("setProjectNested", project);
        });
      })
      .catch((err) => console.error(err.response));
  },
  createProject: (_store, project) => {
    return Projects.createProject(project)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  updateProject: (_store, project) => {
    return Projects.updateProject(project)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  //instalaciones
  getInstallations: (_store, projectId) => {
    return Projects.getInstallations(projectId)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  getInstallationById: (_store, { projectId, installationId }) => {
    return Projects.getInstallationById(projectId, installationId)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  getAllInstallations: (_store) => {
    /*
    this function will get installations for all projects 
    and set them in the state using the setInstallationsByProject mutation
    */
    return new Promise((resolve, reject) => {
      const projects = _store.state.projects;
      let promises = [];
      projects.forEach((project) => {
        const projectInstallation = _store
          .dispatch("getInstallations", project.id)
          .then((installations) => {
            //! revisar formato de respuesta de getInstallations
            _store.commit("setInstallationsByProject", {
              installations: installations.installations,
              projectId: project.id,
            });
          })
          .catch((err) => {
            console.error(err);
          });
        promises.push(projectInstallation);
      });
      Promise.all(promises).then(() => {
        resolve();
      });
    });
  },
  createInstallation: (_store, { projectId, installation }) => {
    return Projects.createInstallation(projectId, installation)
      .then(({ data }) => {
        _store.commit("setInstallation", {
          installation: data.installation,
          steps: data.steps,
          projectId,
        });
        return data;
      })
      .catch((err) => console.error(err.response));
  },
  //incidents
  getIncidents: (_store, { projectId, installationId }) => {
    return Projects.getIncidents(projectId, installationId)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  getIncidentById: (_store, { projectId, installationId, incidentId }) => {
    return Projects.getIncidentById(projectId, installationId, incidentId)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  createIncident: (_store, { projectId, installationId, incident }) => {
    return Projects.createIncident(projectId, installationId, incident)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  updateIncident: (_store, { projectId, installationId, incident }) => {
    return Projects.updateIncident(projectId, installationId, incident)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },

  //administracion del store
  resetProjectsStore: (_store) => {
    _store.commit("setProjects", []);
    _store.commit("setCurrentProject", null);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
