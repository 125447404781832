<template>
  <v-app v-touch="{
    left: () => {
      drawer = false;
    },
  }">
    <the-app-bar v-if="isAuthenticated" :offline-ready="offlineReady" id="app-bar" />
    <v-main class="px-4" v-touch="{
      right: () => swipe('right'),
      left: () => swipe('left'),
      up: () => swipe('up'),
      down: () => swipe('down'),
    }">
      <router-view v-if="showMain" :key="mainKey" v-slot="{ Component }">
        <transition mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
      <loader-icon v-else />
    </v-main>
    <the-form-appbar />
    <v-footer padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} All Rights Reserved —
        <strong>InstallCheck</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import TheAppBar from "./components/TheAppBar.vue";
import TheFormAppbar from "./components/TheFormAppbar.vue";
import { version } from "../package";
import LoaderIcon from "./components/LoaderIcon.vue";

import { ESSENTIAL_ROUTES_AND_MUTATIONS as ROUTES_AND_MUTATIONS, EssentialActions } from "@/utils/essentialActions";

const HOST_ENV = process.env.VUE_APP_HOST_ENV;
export default {
  components: { TheFormAppbar, TheAppBar, LoaderIcon },
  name: "App",
  created() {
    //read from the BroadcastChannel sw-messages
    this.channel = new BroadcastChannel("update-essential");
    this.channel.onmessage = this.updateStore;
  },
  beforeMount() {
    //Info app
    console.log(
      `%cInstallCheck \nVersion: ${version}:${HOST_ENV}`,
      "color: #03a1fc; font-family:serif; font-size: 20px"
    );
    //prevencion de cierre
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
    //deteccion online
    window.addEventListener("online", () =>
      this.$store.commit("pwa/setOffline", false)
    );
    window.addEventListener("offline", () =>
      this.$store.commit("pwa/setOffline", true)
    );
    window.addEventListener("load", (event) => {
      console.log('is online?', navigator.onLine)
      this.$store.commit("pwa/setOffline", !navigator.onLine)
    });
  },
  mounted() { },

  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm("Existen Cambios sin Guardar ¿Salir sin terminar?")) {
        return;
      }
    }
    next();
  },
  beforeDestroy() {
    this.channel.close();
  },
  data: () => ({
    showMain: false,
    channel: null,
    offlineReady: false,
    mainKey: 22
  }),
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },
    swipe(dir) {
      // this.$store.commit("setSwipe", dir);
    },
    updateStore(event) {
      const { payload, url } = event.data;
      //url has the form of https://installcheck-dev.openworldlatam.com/api/v1/installation-templates for example
      //extract the last part of the url after the last slash
      const action = ROUTES_AND_MUTATIONS.get(url.split('/').pop())
      if (!action) return console.log('no action found for this url', url)
      const firstKey = Object.keys(payload)[0];
      if (!payload[firstKey]) return console.log('no payload found for this action', action)
      this.$store.commit(action, payload[firstKey]);
      this.mainKey++;
    },
    renewToken() {
      console.log("renew token btn");
      this.$store.dispatch("auth/renewToken").then((data) => {
        console.log("renew token", data);
      });
    },
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/getIsAuthenticated"];
    },
    isEditing() {
      return this.$store.getters["getIsEditing"];
    },
  },
  watch: {
    isAuthenticated: {
      handler(newValue) {
        if (newValue) {
          this.showMain = false;
          //new way
          const excuterDispatch = async (actions) => {
            for (const action of actions) {
              // console.log(`%cfor loop init action ${action}`, 'color: green; font-size: 20px; font-family: serif;')
              await this.$store.dispatch(action).then((data) => {
                // console.log(`%cfor loop action ${action} done`, 'color: blue; font-size: 20px; font-family: serif;')
              });
              // console.log(`%cfor loop end action ${action}`, 'color: orange; font-size: 20px; font-family: serif;')
            }
          };
          excuterDispatch(EssentialActions).then(() => {
            setTimeout(() => {
              this.showMain = true;
              this.offlineReady = true;
              //check if path 'projects' is matched
              if (this.$route.path.match(/projects/)) return;
              this.$router.push({ name: "projects-list" });

            }, 500);
          });
        } else {
          this.showMain = true;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>
* {
  -webkit-print-color-adjust: exact;
}

@font-face {
  font-family: "Gilmer";
  src: url("@/assets/fonts/GilmerBold.woff");
}

@media print {
  #app-bar {
    display: none;
  }
}
</style>

