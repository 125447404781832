import httpClient from "@/service/httpClient";

const baseEndpoint = `${process.env.VUE_APP_BASE_ENDPOINT}/fleetsafe`;

const getUnits = () => httpClient.get(`${baseEndpoint}/units`);
const getUnit = (id) => httpClient.get(`${baseEndpoint}/units/${id}`);

const postOdometer = (id, odometer) =>
  httpClient.post(`${baseEndpoint}/odometer`, {
    unit_id: id,
    odometer_value: parseInt(odometer),
  });

export default {
  getUnits,
  getUnit,
  postOdometer,
};
