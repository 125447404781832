import Clients from "@/service/clients.api";
import Vue from "vue";

const RELEVANT_ATTRS = ["ip-address", "display-imei", "display-sn"];

const state = {
  clients: [],
  currentClient: {},
  nestedClients: null,
  clientsList: null,
};

const mutations = {
  setClients(state, clients) {
    state.clients = clients ?? [];
  },
  setCurrentClient(state, client) {
    state.currentClient = client ?? {};
  },
  setNestedClientFromCache(state, res) {
    this.commit("clients/setNestedClients", res);
  },
  setNestedClients(state, clientsData) {
    try {
      let clientList = [];
      let nestedClients = new Map();
      clientsData.forEach((client) => {
        clientList.push({ id: client.id, name: client.name });
        const units = client.units;
        const unitsMap = new Map();
        units.forEach((unit) => {
          let unitData = {
            id: unit.id,
            name: unit.name,
          };
          if (unit.installations.length === 0) return;
          unit.installations.forEach((installation) => {
            if (installation.installationSteps.length === 0) return;
            installation.installationSteps.forEach((step) => {
              if (step.form?.formAttrs.length === 0) return;
              const formAttrs = step.form.formAttrs[0];
              if (!formAttrs) {
                console.error("NULL formAttrs in installationStep: ", step.id);
                return;
              }
              const relevantFormAttrs = Object.keys(formAttrs)
                .filter((key) => RELEVANT_ATTRS.includes(key))
                .reduce((obj, key) => {
                  obj[key] = formAttrs[key];
                  return obj;
                }, {});
              unitData = { ...unitData, ...relevantFormAttrs };
            });
          });
          unitsMap.set(unit.id, unitData);
        });
        nestedClients.set(client.id, unitsMap);
      });
      Vue.set(state, "nestedClients", nestedClients);
      Vue.set(state, "clientsList", clientList);
    } catch (err) {
      console.error(err);
    }
  },
};

const getters = {
  clients: (state) => state.clients,
  clientById: (state) => (id) => {
    return state.clients.find((client) => client.id === id);
  },
  currentClient: (state) => state.currentClient,
  nestedClients: (state) => (clientId) => {
    return Array.from(state.nestedClients.get(clientId).values());
  },
  clientsList: (state) => state.clientsList,
};

const actions = {
  getClients: (_store) => {
    return Clients.getClients()
      .then(({ data }) => {
        _store.commit("setClients", data.clients);
        return data.clients;
      })
      .catch((err) => console.error(err.response));
  },
  getClientById: (_store, id) => {
    return Clients.getClientById(id)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  getClientsWithUnits: (_store) => {
    // _store.commit('setNestedClients', NESTED_CLIENTS.clients)
    // return
    if (_store.state.nestedClients) return Promise.resolve();
    return Clients.getClientsWithUnits()
      .then(({ data }) => {
        const clients = data.clients;
        _store.commit("setNestedClients", clients);
        return;
      })
      .catch((err) => console.error(err.response));
  },
  createClient: (_store, client) => {
    return Clients.createClient(client)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  updateClient: (_store, { clientId, client }) => {
    return Clients.updateClient(clientId, client)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  resetClientsStore: ({ commit }) => {
    commit("setClients", []);
    commit("setCurrentClient", {});
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
