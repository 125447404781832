import store from "../store";

const LOGIN = { name: "login" };
const localToken = localStorage.getItem("token");
const localRefreshToken = localStorage.getItem("refresh_token");
const isLocalEmpty = localToken === null || localRefreshToken === null;

const MIN_DURATION = 1 * 60 * 60;
//current unix timestamp
const now = () => Math.floor(Date.now() / 1000);

const getEssencials = () => {
  // store.dispatch("templates/getFullTempForms");
  // store.dispatch("fleetsafe/getUnits");
};

export default (to, from, next) => {
  if (isLocalEmpty) {
    next(LOGIN);
    return null;
  }

  store.dispatch("auth/loginOnReload").then(() => {
    const eventOffline = store.getters["pwa/isOffline"];
    const navigatorOffline = !navigator.onLine;
    const isOffline = navigatorOffline;

    const tokenExpiration = store.getters["auth/expiration"];

    if (isOffline) {
      console.log("isOffline");
      if (tokenExpiration < now()) {
        console.log("token expired");
        store.dispatch("auth/signOut");
        next(LOGIN);
        console.groupEnd();
        return null;
      }
      getEssencials();
      next();
    } else {
      if (tokenExpiration < now() + MIN_DURATION) {
        console.log("token low duration");
        store
          .dispatch("auth/renewToken")
          .then(() => {
            getEssencials();
            next();
            console.groupEnd();
          })
          .catch(() => {
            console.error("renew token failed");
            store.dispatch("auth/signOut");
            next(LOGIN);
            console.groupEnd();
          });
      }
      getEssencials();
      next();
    }
    console.groupEnd();
  });
};
