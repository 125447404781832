const state = {
  nextBtn: {
    isDisabled: false,
    isLoading: false,
    text: "next",
    middleText: "",
  },
};
const mutations = {
  setNextBtn(state, payload) {
    payload.onNext ??= {
      use: false,
      handler: () => {},
    };
    state.nextBtn = payload;
  },
};
const getters = {
  getNextBtn(state) {
    return state.nextBtn;
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
