import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/modules/auth.store";
import templates from "@/store/modules/templates.store";
import fleetsafe from "@/store/modules/fleetsafe.store";
import navbar from "@/store/modules/navbar.store";
import user from "@/store/modules/user.store";
import pwa from "@/store/modules/pwa.store";
import org from "@/store/modules/organizations.store";
import image from "@/store/modules/image.store";
import projects from "@/store/modules/projects.store";
import clients from "@/store/modules/clients.store";
import units from "@/store/modules/units.store";
import steps from "@/store/modules/steps.store";
import newForms from "@/store/modules/newForms.store";
import devices from "@/store/modules/devices.store";
import contacts from "@/store/modules/contacts.store";

import { actionsToUpdate } from "@/utils/essentialActions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isFreshReload: true,
    isEditing: false,
    swipe: false,
  },
  getters: {
    getIsFreshReload: (state) => {
      return state.isFreshReload;
    },
    getIsEditing: (state) => {
      return state.isEditing;
    },
    getSwipe: (state) => {
      return state.swipe;
    },
  },
  mutations: {
    setIsFreshReload: (state, payload) => {
      state.isFreshReload = payload;
    },
    setIsEditing: (state, payload) => {
      state.isEditing = payload;
    },
    setSwipe: (state, payload) => {
      if (payload)
        setTimeout(() => {
          state.swipe = false;
        }, 500);
      state.swipe = payload;
    },
  },
  actions: {
    resetAllStates: ({ dispatch, commit }) => {
      dispatch("auth/resetAuthStore");
      dispatch("clients/resetClientsStore");
      dispatch("contacts/resetContactsStore");
      dispatch("forms/resetFormsStore");
      dispatch("templates/resetTempStore");
      dispatch("image/resetImageStore");
    },
    updateStores: ({ dispatch, commit }) => {
      actionsToUpdate.forEach((action) => {
        dispatch(action);
      });
    },
  },
  modules: {
    auth,
    forms: newForms,
    templates,
    fleetsafe,
    navbar,
    user,
    pwa,
    org,
    image,
    projects,
    clients,
    units,
    steps,
    devices,
    contacts,
  },
});
