import httpClient from "./httpClient";

const baseEndpoint = process.env.VUE_APP_BASE_ENDPOINT;

const getProjects = () => httpClient.get(`${baseEndpoint}/projects`);
const getProjectByID = (projectId) =>
  httpClient.get(`${baseEndpoint}/project/${projectId}`);
const getProjectsWithNestedInstallations = () =>
  httpClient.get(`${baseEndpoint}/projects-with-nested-data`);
const createProject = (project) =>
  httpClient.post(`${baseEndpoint}/project`, project);
const updateProject = (project) =>
  httpClient.put(`${baseEndpoint}/project`, project);

// installations api

const getInstallations = (projectId) =>
  httpClient.get(`${baseEndpoint}/projects/${projectId}/installations`);
const getInstallationById = (projectId, installationId) =>
  httpClient.get(
    `${baseEndpoint}/projects/${projectId}/installations/${installationId}`
  );
const createInstallation = (projectId, installation) =>
  httpClient.post(
    `${baseEndpoint}/projects/${projectId}/installations`,
    installation
  );

//installations incidents api

const getIncidents = (projectId, installationId) =>
  httpClient.get(
    `${baseEndpoint}/projects/${projectId}/installations/${installationId}/incidents`
  );
const getIncidentById = (projectId, installationId, incidentId) =>
  httpClient.get(
    `${baseEndpoint}/projects/${projectId}/installations/${installationId}/incidents/${incidentId}`
  );
const createIncident = (projectId, installationId, incident) =>
  httpClient.post(
    `${baseEndpoint}/projects/${projectId}/installations/${installationId}/incidents`,
    incident
  );
const updateIncident = (projectId, installationId, incidentId, incident) =>
  httpClient.put(
    `${baseEndpoint}/projects/${projectId}/installations/${installationId}/incidents/${incidentId}`,
    incident
  );

export default {
  getProjects,
  getProjectByID,
  getProjectsWithNestedInstallations,
  createProject,
  updateProject,
  getInstallations,
  getInstallationById,
  createInstallation,
  getIncidents,
  getIncidentById,
  createIncident,
  updateIncident,
};
