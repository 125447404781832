import httpClient from "@/service/httpClient";

const baseEndpoint = process.env.VUE_APP_BASE_ENDPOINT;

//form templates api
const getFormTemplates = () => httpClient.get(`${baseEndpoint}/form-templates`);
const getFormTemplateById = (formTemplateId) =>
  httpClient.get(`${baseEndpoint}/form-templates/${formTemplateId}`);
const getFullFormTemplates = () =>
  httpClient.get(`${baseEndpoint}/full-form-templates`);
//TODO: add the rest of the form templates api (creates, updates, deletes)

//installation-templates api
const getInstallationTemplates = () =>
  httpClient.get(`${baseEndpoint}/installation-templates`);
const getInstallationTemplateById = (installationTemplateId) =>
  httpClient.get(
    `${baseEndpoint}/installation-templates/${installationTemplateId}`
  );

//installation step templates api

const getInstallationStepsTemplatesByInstallationId = (
  installationTemplateId
) =>
  httpClient.get(
    `${baseEndpoint}/installation-templates/${installationTemplateId}/steps`
  );
const getInstallationStepsTemplateById = (
  installationTemplateId,
  installationStepTemplateId
) =>
  httpClient.get(
    `${baseEndpoint}/installation-templates/${installationTemplateId}/steps/${installationStepTemplateId}`
  );

export default {
  getFormTemplates,
  getFormTemplateById,
  getFullFormTemplates,
  getInstallationTemplates,
  getInstallationTemplateById,
  getInstallationStepsTemplatesByInstallationId,
  getInstallationStepsTemplateById,
};
