import httpClient from "./httpClient";

const baseEndpoint = process.env.VUE_APP_BASE_ENDPOINT;

const getInstallationSteps = (projectId, installationId) =>
  httpClient.get(
    `${baseEndpoint}/projects/${projectId}/installations/${installationId}/steps`
  );

const getInstallationStepById = (projectId, installationId, stepNumber) =>
  httpClient.get(
    `${baseEndpoint}/projects/${projectId}/installations/${installationId}/steps/${stepNumber}`
  );

const postInstallationStep = (projectId, installationId, stepId, payload) =>
  httpClient.post(
    `${baseEndpoint}/projects/${projectId}/installations/${installationId}/steps/${stepId}/forms`,
    payload
  );

export default {
  getInstallationSteps,
  getInstallationStepById,
  postInstallationStep,
};
