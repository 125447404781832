import Organizations from "@/service/organizations.api";

const state = {};

const mutations = {};

const getters = {};

const actions = {
  getOrganizations: () => {
    return Organizations.getOrganizations()
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  getCurrentOrganization: () => {
    return Organizations.getCurrentOrganization()
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  getOrganizationByID: (_store, id) => {
    return Organizations.getOrganizationByID(id)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  getOrganizationByName: (_store, name) => {
    return Organizations.getOrganizationByName(name)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  updateOrganizationPermission: (_store, { name, permissions }) => {
    return Organizations.updateOrganizationPermission(name, permissions)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
