import httpClient from "./httpClient";
const baseEndpoint = process.env.VUE_APP_BASE_ENDPOINT;

const getUnits = (clientId) =>
  httpClient.get(`${baseEndpoint}/clients/${clientId}/units`);

const getUnitById = (clientId, unitId) =>
  httpClient.get(`${baseEndpoint}/clients/${clientId}/units/${unitId}`);

const createUnit = (clientId, unit) =>
  httpClient.post(`${baseEndpoint}/clients/${clientId}/units`, unit);

const updateUnit = (clientId, unitId, unit) =>
  httpClient.put(`${baseEndpoint}/clients/${clientId}/units/${unitId}`, unit);

export default {
  getUnits,
  getUnitById,
  createUnit,
  updateUnit,
};
